@media (min-width:05px) and (max-width:650px) {



    .historycontaner {
        background-image: url('https://images.pexels.com/photos/544917/pexels-photo-544917.jpeg?cs=srgb&dl=pexels-%E7%A5%9D-%E9%B9%A4%E6%A7%90-544917.jpg&fm=jpg');
        /* background-image: url('../img/sample.jpg'); */
        background-position: center;
        background-size: 100%;
        z-index: 1;
        position: relative;
        padding: 2rem;
        color: white;
    }

    .historycontaner::after {
        background: hsl(0deg 0% 0% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .histrorybox1 {
        padding: 1rem;
        text-align: center;
    }

    .histrorybox1 h6 {
        font-size: 1.5rem;
        padding-top: 2rem;
    }

    .histrorybox1 p {
        font-size: 1.8rem;
        font-weight: 100;
        padding-bottom: 1rem;

    }

    .historybox2 {
        display: flex;
        flex-wrap: wrap;
    }

    .historybox {
        display: flex;
        background-color: white;
        color: black;
        margin: 0.6rem 0.7rem;
        padding: 1.3rem;
        border-radius: 12px;
    }

    .historybox .historyboximg i {
        font-size: 2.5rem;
        padding: 1rem;

    }

    .historyboxcontent h5 {
        font-size: 1.1rem;
        font-weight: 100;
        padding: 0.4rem;
        padding-top: 0.6rem;

    }

    .historyboxcontent p {
        font-size: 1.1rem;
        font-weight: 100;
        padding: 0.4rem;
        padding-bottom: 1rem;
        text-align: justify;

    }





}
@media (min-width:650px) and (max-width:900px) {



    .historycontaner {
        background-image: url('https://images.pexels.com/photos/544917/pexels-photo-544917.jpeg?cs=srgb&dl=pexels-%E7%A5%9D-%E9%B9%A4%E6%A7%90-544917.jpg&fm=jpg');
        background-position: center;
        background-size: 100%;
        z-index: 1;
        position: relative;
        padding: 2rem;
        color: white;
    }

    .historycontaner::after {
        background: hsl(0deg 0% 0% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .histrorybox1 {
        padding: 1rem;
        text-align: center;
    }

    .histrorybox1 h6 {
        font-size: 1.5rem;
        padding-top: 2rem;
    }

    .histrorybox1 p {
        font-size: 1.8rem;
        font-weight: 100;
        padding-bottom: 1rem;

    }

    .historybox2 {
        display: flex;
    }

    .historybox {
        display: flex;
        background-color: white;
        color: black;
        margin: 2.6rem 0.7rem;
        padding: 0.3rem;
        border-radius: 12px;
    }

    .historybox .historyboximg i {
        font-size: 2rem;
        padding: 0.4rem;

    }

    .historyboxcontent h5 {
        font-size: 1rem;
        font-weight: 100;
        padding: 0.4rem;
        padding-top: 0.6rem;

    }

    .historyboxcontent p {
        font-size: 1rem;
        font-weight: 100;
        padding: 0.4rem;
        padding-bottom: 1rem;
        text-align: justify;

    }





}

@media (min-width:900px) and (max-width:1241px) {



    .historycontaner {
        background-image: url('https://images.pexels.com/photos/544917/pexels-photo-544917.jpeg?cs=srgb&dl=pexels-%E7%A5%9D-%E9%B9%A4%E6%A7%90-544917.jpg&fm=jpg');
        background-position: center;
        background-size: 100%;
        z-index: 1;
        position: relative;
        padding: 2rem;
        color: white;
    }

    .historycontaner::after {
        background: hsl(0deg 0% 0% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .histrorybox1 {
        padding: 1rem;
        text-align: center;
    }

    .histrorybox1 h6 {
        font-size: 1.5rem;
        padding-top: 2rem;
    }

    .histrorybox1 p {
        font-size: 1.8rem;
        font-weight: 100;
        padding-bottom: 1rem;

    }

    .historybox2 {
        display: flex;
    }

    .historybox {
        display: flex;
        background-color: white;
        color: black;
        margin: 2.6rem 0.7rem;
        padding: 1.3rem;
        border-radius: 12px;
    }

    .historybox .historyboximg i {
        font-size: 2.5rem;
        padding: 1rem;

    }

    .historyboxcontent h5 {
        font-size: 1.1rem;
        font-weight: 100;
        padding: 0.4rem;
        padding-top: 0.6rem;

    }

    .historyboxcontent p {
        font-size: 1.1rem;
        font-weight: 100;
        padding: 0.4rem;
        padding-bottom: 1rem;
        text-align: justify;

    }


}

@media (min-width:1241px) {

    .historycontaner {
        background-image: url('https://images.pexels.com/photos/544917/pexels-photo-544917.jpeg?cs=srgb&dl=pexels-%E7%A5%9D-%E9%B9%A4%E6%A7%90-544917.jpg&fm=jpg');
        background-position: center;
        background-size: 100%;
        z-index: 1;
        position: relative;
        padding: 2rem;
        color: white;
    }

    .historycontaner::after {
        background: hsl(0deg 0% 0% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .histrorybox1 {
        padding: 1rem;
        text-align: center;
    }

    .histrorybox1 h6 {
        font-size: 1.5rem;
        padding-top: 2rem;
    }

    .histrorybox1 p {
        font-size: 1.8rem;
        font-weight: 100;
        padding-bottom: 1rem;

    }

    .historybox2 {
        display: flex;
    }

    .historybox {
        display: flex;
        background-color: white;
        color: black;
        margin: 2.6rem 0.7rem;
        padding: 1.3rem;
        border-radius: 12px;
    }

    .historybox .historyboximg i {
        font-size: 2.5rem;
        padding: 1rem;

    }

    .historyboxcontent h5 {
        font-size: 1.1rem;
        font-weight: 100;
        padding: 0.4rem;
        padding-top: 0.6rem;

    }

    .historyboxcontent p {
        font-size: 1.1rem;
        font-weight: 100;
        padding: 0.4rem;
        padding-bottom: 1rem;
        text-align: justify;

    }



}