

@media (min-width:05px) and (max-width:600px){
   
    .aboutsec3container{
        background-image: url('https://www.glassjacobson.com/wp-content/uploads/2018/08/What-Does-a-Business-Consultant-Do.png');
        background-position: center;
        background-size: 100%;
        z-index: 1;
        position: relative;
    }
    
    .aboutsec3container::after{
        background: hsl(0deg 13.17% 6.68% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
    .aboutsec3boxs{
        color: white;
        width: 90%;
        margin: auto;
    }
    .aboutsec3boxs h2{
        font-size: 2rem;
        padding: 1rem 0rem;
    }
    .aboutsec3boxs p{
        width: 90%;
        font-size: 1.1rem;
        font-weight: 100;
    }
    
    .aboutsec3boxs .aboutsec3box2{
        background-color: white;
        margin: 3rem 0rem;
        padding: 2rem 0.3rem;
        border-radius: 1.2rem;
    }
    .aboutsec3box21content{
        color: black;
        text-align: justify;
    }
    .aboutsec3box21img .ilogo {
        color: #ff6500;
        font-size: 2rem;
        margin: 1rem;
    }
    .aboutsec3button button{
        padding: 0.9rem;
        margin: 0.6rem;
        font-size: 1rem;
        background-color: transparent;
        color: white;
        border-radius: 35px;
        /* float: right; */
        border: 1px solid white;
        margin-bottom: 4rem;
    }
    .aboutsec3button {
       text-align: right;
    }
    
    .aboutsec3box21{
        display: flex;
        padding: 1rem;
    }
    .aboutsec3box2{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }


    .aboutsec3button button a{
        color: white;
        text-decoration: none;
    }

    
    
    
    
           
}

@media (min-width:600px) and (max-width:900px){

    .aboutsec3container{
        background-image: url('https://www.glassjacobson.com/wp-content/uploads/2018/08/What-Does-a-Business-Consultant-Do.png');
        background-position: center;
        background-size: 100%;
        z-index: 1;
        position: relative;
    }
    
    .aboutsec3container::after{
        background: hsl(0deg 13.17% 6.68% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
    .aboutsec3boxs{
        color: white;
        width: 90%;
        margin: auto;
    }
    .aboutsec3boxs h2{
        font-size: 2rem;
        padding: 1rem 0rem;
    }
    .aboutsec3boxs p{
        /* width: 70%; */
        font-size: 1.1rem;
        font-weight: 100;
    }
    
    .aboutsec3boxs .aboutsec3box2{
        background-color: white;
        margin: 3rem 0rem;
        padding: 2rem 0.3rem;
        border-radius: 1.2rem;
    }
    .aboutsec3box21content{
        color: black;
        text-align: justify;
        
    }
    .aboutsec3box21img .ilogo {
        color: #ff6500;
        font-size: 3rem;
        margin: 1rem;
    }
    .aboutsec3button button{
        padding: 0.9rem;
        margin: 0.6rem;
        font-size: 1.3rem;
        background-color: transparent;
        color: white;
        border-radius: 35px;
        /* float: right; */
        border: 1px solid white;
        margin-bottom: 4rem;
    }
    .aboutsec3button {
       text-align: right;
    }
    
    .aboutsec3box21{
        display: flex;
        padding: 2rem;
    }
    .aboutsec3box2{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .aboutsec3button button a{
        color: white;
        text-decoration: none;
    }

    
    
    
    
       
       
        
}
@media (min-width:900px) and (max-width:1241px){

    .aboutsec3container{
        background-image: url('https://www.glassjacobson.com/wp-content/uploads/2018/08/What-Does-a-Business-Consultant-Do.png');
        background-position: center;
        background-size: 100%;
        z-index: 1;
        position: relative;
    }
    
    .aboutsec3container::after{
        background: hsl(0deg 13.17% 6.68% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
    .aboutsec3boxs{
        color: white;
        width: 90%;
        margin: auto;
    }
    .aboutsec3boxs h2{
        font-size: 2rem;
        padding: 1rem 0rem;
    }
    .aboutsec3boxs p{
        width: 90%;
        font-size: 1.1rem;
        font-weight: 100;
    }
    
    .aboutsec3boxs .aboutsec3box2{
        background-color: white;
        margin: 3rem 0rem;
        padding: 2rem 0.3rem;
        border-radius: 1.2rem;
    }
    .aboutsec3box21content{
        color: black;
        text-align: justify;
    }
    .aboutsec3box21img .ilogo {
        color: #ff6500;
        font-size: 3rem;
        margin: 1rem;
    }
    .aboutsec3button button{
        padding: 0.9rem;
        margin: 0.6rem;
        font-size: 1.3rem;
        background-color: transparent;
        color: white;
        border-radius: 35px;
        /* float: right; */
        border: 1px solid white;
        margin-bottom: 4rem;
    }
    .aboutsec3button {
       text-align: right;
    }
    
    .aboutsec3box21{
        display: flex;
        width: 21rem;
    }
    .aboutsec3box2{
        display: flex;
        justify-content: center;
    }

    .aboutsec3button button a{
        color: white;
        text-decoration: none;
    }

    
    
    
    
          
        
}

@media (min-width:1241px) {
    .aboutsec3container{
        background-image: url('https://www.glassjacobson.com/wp-content/uploads/2018/08/What-Does-a-Business-Consultant-Do.png');
        background-position: center;
        background-size: 100%;
        z-index: 1;
        position: relative;
    }
    
    .aboutsec3container::after{
        background: hsl(0deg 13.17% 6.68% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
    .aboutsec3boxs{
        color: white;
        width: 90%;
        margin: auto;
    }
    .aboutsec3boxs h2{
        font-size: 2rem;
        padding: 1rem 0rem;
    }
    .aboutsec3boxs p{
        width: 90%;
        font-size: 1.1rem;
        font-weight: 100;
    }
    
    .aboutsec3boxs .aboutsec3box2{
        background-color: white;
        margin: 3rem 0rem;
        padding: 2rem 0.3rem;
        border-radius: 1.2rem;
    }
    .aboutsec3box21content{
        color: black;
        text-align: justify;
    }
    .aboutsec3box21img .ilogo {
        color: #ff6500;
        font-size: 3rem;
        margin: 1rem;
    }
    .aboutsec3button button{
        padding: 0.9rem;
        margin: 0.6rem;
        font-size: 1.3rem;
        background-color: transparent;
        color: white;
        border-radius: 35px;
        /* float: right; */
        border: 1px solid white;
        margin-bottom: 4rem;
    }
    .aboutsec3button {
       text-align: right;
    }
    
    .aboutsec3box21{
        display: flex;
        width: 22rem;
    }
    .aboutsec3box2{
        display: flex;
        justify-content: center;
    }

    .aboutsec3button button a{
        color: white;
        text-decoration: none;
    }


    


    
    
    
    
       
       
        
}