.crewcontainer{
/* background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSNSdaTlU8Db4CWYXBDxUbL5eMJWdqG8YLVA&usqp=CAU'); */
/* background-position: center; */
/* background-size: cover; */
/* opacity: 0.7; */
background-color: rgb(247 233 233);
padding-bottom: 5rem;
}

.crewheading{
    text-align: center;
}
.crewheading h4{
    padding: 0.5rem;
    /* margin: 0.5rem; */
    font-size: 1.1rem;
    font-weight: 200;
    padding-top: 5rem;
}
.crewheading h2{
    font-size: 3rem;
}
.crewheading p{
    font-size: 1.4rem;
    margin-bottom: 3rem;
    margin-top: 0.8rem;

}

.crewboxcontainer{
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
}
.crewboxs{
    margin: 1rem;
    padding: 1rem;
    width: 17rem;
    position: relative;
    border-radius: 12px;
    background-color: white;
    box-shadow: 1px 1px 25px #716868;
}
.crewboxs:hover{
    transform: scale(1.09);
    transition: all 2s;
}


.crewimgbox{
    background-color: rgb(106, 101, 101);
    text-align: center;
    width: 7rem;
    height: 7rem;
    border-radius: 100px;
    overflow: hidden;
    margin: 1.4rem auto;
}
.crewimgbox img{
    width: 100%;
    height: 100%;
}
.crewcontentbox p{
    font-size: 1.1rem;

}
.crewcontentbox h4{
    font-size: 1.6rem;
    padding: 0.5rem;

}
.crewcontentbox h5{
    font-size: 1.2rem;
    /* padding: 0.5rem; */
    font-weight: 100;
    padding-bottom: 1rem;

}
.crewlinks .crewlink{
    font: 1rem;
    padding: 0.3rem;
    margin: 0.5rem;
}
