
@media (min-width:05px) and (max-width:657px){

    
    .slideruper{
        text-align: center;
        width: 90%;
        margin: auto;
        padding: 3rem 0rem;
    }
    .slideruper h3{
        font-size: 1.8rem;
        width: 90%;
        margin: auto;
        margin-bottom: 4rem;
    }
    
    .slidermainbox{
        display: flex;
        justify-content: center;
        text-align: center;
    }
    
    .slidermainbox2{
        max-width: 1120px;
        width: 100%;
        background-color: grey;
    }
    .card .imagebox{
        height: 200px;
        width: 1rem;
    }
    .card .imagebox img{
        height: 100%;
    }
    .card .profilrDetails img{
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }
    .card .profilrDetails {
        display: flex;
        text-align: left;
        column-gap: 12px;
        padding: 15px;
        
    }
    
    .profilrDetails .name{
        font-size: 15px;
        font-weight: 500;
    
    }
    
    .profilrDetails .job{
        font-size: 12px;
        font-weight: 500;
        color: #4d4d4d;
    
    }
    
    
    .card{
        background-color: #f3eaea;
        width: 15rem;
    }
    .namejob h3,h4{
        margin: 0;
    
    }
    
    
    .cardslidercontainer{
        width: 90%;
        margin: auto;
    }
    
    
    
    .cardimgbox .cardimgboxi{
        color:#ff6500;
        /* z-index: 2; */
        font-size: 3rem;
        padding: 1rem;
    }
    .cardboximg .cardimgbox{
        background-color: rgb(251, 247, 247);
        width: 6rem;
        height: 6rem;
        margin: 1rem auto;
        border-radius: 22% 78% 64% 36% / 46% 62% 38% 54% ;
    }
    .cardcontaner{
        width: 22rem;
        height: 20rem;
        /* border: 2px solid red; */
        text-align: center;
        margin: auto;
        
    }
    .cardbox h3{
        font-size: 1.8rem;
        font-weight: bold;
        padding: 0.7rem;
        margin: 0;
    }
    .cardbox p{
        
        /* font-weight: bold; */
        padding: 0rem 0.8rem;
        margin: 0;
    }
    
    .cardcontaners{
        display: flex;
        justify-content: center;
        text-align: center;
        /* overflow: scroll; */
    }
    
    .cardcontaner:hover .cardimgbox  {
        background-color: black;
        transition: all 100ms;
    }
    .cardcontaner:hover  .cardimgboxi {
        color: white;
    }
     .cardboxbutton a span {
        display: none;
    }
    .cardcontaner:hover .cardboxbutton a span {
        display: block;
    }
    
 

}
@media (min-width:657px) and (max-width:925px){
    .slideruper{
        text-align: center;
        width: 90%;
        margin: auto;
        padding: 3rem 0rem;
    }
    .slideruper h3{
        font-size: 2.2rem;
        width: 70%;
        margin: auto;
        margin-bottom: 4rem;
    }
    
    .slidermainbox{
        display: flex;
        justify-content: center;
        text-align: center;
    }
    
    .slidermainbox2{
        max-width: 1120px;
        width: 100%;
        background-color: grey;
    }
    .card .imagebox{
        height: 200px;
        width: 1rem;
    }
    .card .imagebox img{
        height: 100%;
    }
    .card .profilrDetails img{
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }
    .card .profilrDetails {
        display: flex;
        text-align: left;
        column-gap: 12px;
        padding: 15px;
        
    }
    
    .profilrDetails .name{
        font-size: 15px;
        font-weight: 500;
    
    }
    
    .profilrDetails .job{
        font-size: 12px;
        font-weight: 500;
        color: #4d4d4d;
    
    }
    
    
    .card{
        background-color: #f3eaea;
        width: 15rem;
    }
    .namejob h3,h4{
        margin: 0;
    
    }
    
    
    .cardslidercontainer{
        width: 90%;
        margin: auto;
    }
    
    
    
    .cardimgbox .cardimgboxi{
        color:#ff6500;
        /* z-index: 2; */
        font-size: 3rem;
        padding: 1rem;
    }
    .cardboximg .cardimgbox{
        background-color: rgb(251, 247, 247);
        width: 6rem;
        height: 6rem;
        margin: 1rem auto;
        border-radius: 22% 78% 64% 36% / 46% 62% 38% 54% ;
    }
    .cardcontaner{
        width: 22rem;
        height: 20rem;
        /* border: 2px solid red; */
        text-align: center;
        margin: auto;
        
    }
    .cardbox h3{
        font-size: 1.8rem;
        font-weight: bold;
        padding: 0.7rem;
        margin: 0;
    }
    .cardbox p{
        
        /* font-weight: bold; */
        padding: 0rem 0.8rem;
        margin: 0;
    }
    
    .cardcontaners{
        display: flex;
        justify-content: center;
        text-align: center;
        /* overflow: scroll; */
    }
    
    .cardcontaner:hover .cardimgbox  {
        background-color: black;
        transition: all 100ms;
    }
    .cardcontaner:hover  .cardimgboxi {
        color: white;
    }
     .cardboxbutton a span {
        display: none;
    }
    .cardcontaner:hover .cardboxbutton a span {
        display: block;
    }
    
           
}
@media (min-width:925px) and (max-width:1241px){
 

    .slideruper{
        text-align: center;
        width: 90%;
        margin: auto;
        padding: 3rem 0rem;
    }
    .slideruper h3{
        font-size: 2.5rem;
        width: 50%;
        margin: auto;
        margin-bottom: 4rem;
    }
    
    .slidermainbox{
        display: flex;
        justify-content: center;
        text-align: center;
    }
    
    .slidermainbox2{
        max-width: 1120px;
        width: 100%;
        background-color: grey;
    }
    .card .imagebox{
        height: 200px;
        width: 1rem;
    }
    .card .imagebox img{
        height: 100%;
    }
    .card .profilrDetails img{
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }
    .card .profilrDetails {
        display: flex;
        text-align: left;
        column-gap: 12px;
        padding: 15px;
        
    }
    
    .profilrDetails .name{
        font-size: 15px;
        font-weight: 500;
    
    }
    
    .profilrDetails .job{
        font-size: 12px;
        font-weight: 500;
        color: #4d4d4d;
    
    }
    
    
    .card{
        background-color: #f3eaea;
        width: 15rem;
    }
    .namejob h3,h4{
        margin: 0;
    
    }
    
    
    .cardslidercontainer{
        width: 90%;
        margin: auto;
    }
    
    
    
    .cardimgbox .cardimgboxi{
        color:#ff6500;
        /* z-index: 2; */
        font-size: 3rem;
        padding: 1rem;
    }
    .cardboximg .cardimgbox{
        background-color: rgb(251, 247, 247);
        width: 6rem;
        height: 6rem;
        margin: 1rem auto;
        border-radius: 22% 78% 64% 36% / 46% 62% 38% 54% ;
    }
    .cardcontaner{
        width: 22rem;
        height: 20rem;
        /* border: 2px solid red; */
        text-align: center;
        margin: auto;
        
    }
    .cardbox h3{
        font-size: 1.8rem;
        font-weight: bold;
        padding: 0.7rem;
        margin: 0;
    }
    .cardbox p{
        
        /* font-weight: bold; */
        padding: 0rem 0.8rem;
        margin: 0;
    }
    
    .cardcontaners{
        display: flex;
        justify-content: center;
        text-align: center;
        /* overflow: scroll; */
    }
    
    .cardcontaner:hover .cardimgbox  {
        background-color: black;
        transition: all 100ms;
    }
    .cardcontaner:hover  .cardimgboxi {
        color: white;
    }
     .cardboxbutton a span {
        display: none;
    }
    .cardcontaner:hover .cardboxbutton a span {
        display: block;
    }
    
    
}
@media (min-width:1241px) {
    
    .slideruper{
        text-align: center;
        width: 90%;
        margin: auto;
        padding: 3rem 0rem;
    }
    .slideruper h3{
        font-size: 3rem;
        width: 50%;
        margin: auto;
        margin-bottom: 4rem;
    }
    .slideruper h3 span{
       color: #ff6500;
    }
    
    .slidermainbox{
        display: flex;
        justify-content: center;
        text-align: center;
    }
    
    .slidermainbox2{
        max-width: 1120px;
        width: 100%;
        background-color: grey;
    }
    .card .imagebox{
        height: 200px;
        width: 1rem;
    }
    .card .imagebox img{
        height: 100%;
    }
    .card .profilrDetails img{
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }
    .card .profilrDetails {
        display: flex;
        text-align: left;
        column-gap: 12px;
        padding: 15px;
        
    }
    
    .profilrDetails .name{
        font-size: 15px;
        font-weight: 500;
    
    }
    
    .profilrDetails .job{
        font-size: 12px;
        font-weight: 500;
        color: #4d4d4d;
    
    }
    
    
    .card{
        background-color: #f3eaea;
        width: 15rem;
    }
    .namejob h3,h4{
        margin: 0;
    
    }
    
    
    .cardslidercontainer{
        width: 90%;
        margin: auto;
    }
    
    
    
    .cardimgbox .cardimgboxi{
        color:#ff6500;
        /* z-index: 2; */
        font-size: 3rem;
        padding: 1rem;
    }
    .cardboximg .cardimgbox{
        background-color: rgb(251, 247, 247);
        width: 6rem;
        height: 6rem;
        margin: 1rem auto;
        border-radius: 22% 78% 64% 36% / 46% 62% 38% 54% ;
    }
    .cardcontaner{
        width: 22rem;
        height: 20rem;
        /* border: 2px solid red; */
        text-align: center;
        margin: auto;
        
    }
    .cardbox h3{
        font-size: 1.8rem;
        font-weight: bold;
        padding: 0.7rem;
        margin: 0;
    }
    .cardbox p{
        
        /* font-weight: bold; */
        padding: 0rem 0.8rem;
        margin: 0;
    }
    
    .cardcontaners{
        display: flex;
        justify-content: center;
        text-align: center;
        /* overflow: scroll; */
    }
    
    .cardcontaner:hover .cardimgbox  {
        background-color: black;
        transition: all 100ms;
    }
    .cardcontaner:hover  .cardimgboxi {
        color: white;
    }
     .cardboxbutton a span {
        display: none;
    }
    .cardcontaner:hover .cardboxbutton a span {
        display: block;
    }
    
    
}