.aboutrngcontaner{
    /* background-color: rgb(205, 232, 237); */
}
.aboutrngbox{
    width: 85%;
    margin: auto;
}
.aboutrngbox h1 {
    padding: 0.3rem;
    padding-top: 1rem 0rem;
    
}
.aboutrngbox h1 span{
    color: blue;
    
    
}
.aboutrngbox p{
    font-size: 1.2rem;
    padding: 1rem;
}
.aboutrngbox h6{
    font-size: 1.2rem;
}