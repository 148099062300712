
@media (min-width:05px) and (max-width:700px) {

    .historysec3box{
        display: flex;
        padding: 3rem;
        position: relative;
        flex-wrap: wrap;
    }
    .historysec3container .historybox1 h2{
        text-align: center;
        font-size: 4rem;
    }
    .historysec3container .historybox1 h5{
        font-size: 1.3rem;
        font-weight: 100;
    }
    .historysec3container .historybox1 h4{
        font-size: 1.6rem;
    }
    .historysec3container .historybox1 p{
        font-size: 1rem;
    }
    
     .historybox2{
      padding: 2rem 0rem;
    }
    


}
@media (min-width:700px) and (max-width:900px) {


    .historysec3box{
        display: flex;
        padding: 3rem;
        position: relative;
    }
    .historysec3container .historybox1 h2{
        text-align: center;
        font-size: 3rem;
    }
    .historysec3container .historybox1 h5{
        font-size: 1.2rem;
        font-weight: 100;
    }
    .historysec3container .historybox1 h4{
        font-size: 1.5rem;
    }
    .historysec3container .historybox1 p{
        font-size: 1rem;
    }
    
     .historybox2{
      padding: 2rem;
    }
    
}

@media (min-width:900px) and (max-width:1241px) {

    
.historysec3box{
    display: flex;
    padding: 3rem;
    position: relative;
}
.historysec3container .historybox1 h2{
    text-align: center;
    font-size: 4rem;
}
.historysec3container .historybox1 h5{
    font-size: 1.3rem;
    font-weight: 100;
}
.historysec3container .historybox1 h4{
    font-size: 1.6rem;
}
.historysec3container .historybox1 p{
    font-size: 1rem;
}

 .historybox2{
  padding: 2rem;
}

}

@media (min-width:1241px) {


    .historysec3box{
        display: flex;
        padding: 3rem;
        position: relative;
    }
    .historysec3container .historybox1 h2{
        text-align: center;
        font-size: 4rem;
    }
    .historysec3container .historybox1 h5{
        font-size: 1.3rem;
        font-weight: 100;
    }
    .historysec3container .historybox1 h4{
        font-size: 1.6rem;
    }
    .historysec3container .historybox1 p{
        font-size: 1rem;
    }
    
     .historybox2{
      padding: 2rem;
    }
    

} 

