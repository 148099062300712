.teamcontainer{
    padding: 2rem;
}

.teamboxs{
    position: relative;
display: flex;
justify-content: space-around;
flex-wrap: wrap;

}

.teambox{
    position: relative;
    width: 15rem;
    height: 20rem;
    background-color: rgb(108, 94, 94);
    margin: 1rem;

}

.teamimg{
    width: 100%;
    height: 100%;
}
.teamimg img{
    
    width: 100%;
    height: 100%;
    object-fit:cover;

}
.teamovercontent{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:rgba(0, 0, 0, 0.75) ;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 1s ease;
    color: white;
    text-align: center;
}
.teamovercontent h2{
    padding: 0.5rem;
}

.teamlinks .ilogo{
    padding: 0.4rem;
}


.teambox:hover .teamovercontent{
    height: 30%;
} 
.teambox:hover .teamimg img{
    scale: 0.8;
    transition: all 1s;
} 

/* .teamboxs:hover .teamovercontent{
    height: 30%;
}
.teamboxs:hover .teamimg img{

    scale: 0.8;
    transition: all 2s;
} */

.teambox{
    box-shadow: 1px 1px 12px blue;
}