

@media (min-width:05px) and (max-width:761px){
    .aboutsec2container{
        /* width: 90%; */
        margin: auto;
    }
    .aboutsec2box1{
        display: flex;
        justify-content: space-between;
        margin: 4rem 0rem;
        padding: 0rem 3rem;
        flex-wrap: wrap;
    }
    .aboutsec2card2{
        width: 80rem;
    }
    .aboutsec2card2 img{
        width: 100%;
    }
    
    .aboutsec2card1 h1{
        font-size: 2rem;
        margin: 1rem 0rem;
    }
    .aboutsec2card1 p{
        font-size: 1.3rem;
        margin: 1rem 0rem;
        text-align: justify;
    }
    
    .aboutsec2boxs{
        display: flex;
        width: 25rem;
        /* border: 1px solid black; */
        margin: 0.4rem;
    }
    .aboutsec2boxsimg{
        width: 4rem;
        height: 4rem;
        /* background-color: rgb(160, 160, 220); */
        border-radius: 200px;
        margin: 2rem 0.5rem;
        /* overflow: hidden; */
    }
    .aboutsec2boxsimg img{
        width: 100%; 
        height: 100%;
       
    }
    .aboutsec2boxscontent{
        padding: 0.3rem;
        width: 17rem;
    }
    .aboutsec2boxscontent p{
        font-size: 1rem;
        line-height: 2rem;
        text-align: justify;
    }
    .aboutsec2boxscontent h2{
        font-size: 1.9rem;
        margin: 0;
    }
    
    .aboutsec2box2{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 3rem 0rem;
    }

      
    .aboutsec2boxsimg i{
        font-size: 3rem;
        text-align: center;
        color: #ff6500;
    }
            
}

@media (min-width:761px) and (max-width:1060px){

    .aboutsec2container{
        margin: auto;
    }
    .aboutsec2box1{
        display: flex;
        justify-content: space-between;
        margin: 4rem 0rem;
        padding: 0rem 3rem;
    }
    .aboutsec2card2{
        width: 100rem;
        /* margin: 2rem 1rem; */
        margin-top: 5rem;
        margin-left: 1rem;
    }
    .aboutsec2card2 img{
        width: 100%;
    }
    
    .aboutsec2card1 h1{
        font-size: 2rem;
        margin: 1rem 0rem;
    }
    .aboutsec2card1 p{
        font-size: 1.2rem;
        margin: 1rem 0rem;
        text-align: justify;
    }
    .aboutsec2boxs{
        display: flex;
        width: 25rem;
        margin: 0.4rem;
    }
    .aboutsec2boxsimg{
        width: 4rem;
        height: 4rem;
        /* background-color: rgb(160, 160, 220); */
        border-radius: 200px;
        margin: 2rem 0.5rem;
        /* overflow: hidden; */
    }
    .aboutsec2boxsimg img{
        width: 100%; 
        height: 100%;  
    }
    .aboutsec2boxscontent{
        padding: 0.3rem;
        width: 17rem;
    }
    .aboutsec2boxscontent p{
        font-size: 1rem;
        line-height: 2rem;
        text-align: justify;
    }
    .aboutsec2boxscontent h2{
        font-size: 1.9rem;
        margin: 0;
    }    
    .aboutsec2box2{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 3rem 0rem;
    }   

      
    .aboutsec2boxsimg i{
        font-size: 3rem;
        text-align: center;
        color: #ff6500;
    }
}

@media (min-width:1060px) and (max-width:1200px){

    .aboutsec2container{
        /* width: 90%; */
        margin: auto;
    }
    .aboutsec2box1{
        display: flex;
        justify-content: space-between;
        margin: 4rem 0rem;
        padding: 0rem 3rem;
    }
    .aboutsec2card2{
        width: 118rem;
        /* margin: 5rem 1rem; */
        margin-top: 7rem;
        margin-left: 1rem;
    }
    .aboutsec2card2 img{
        width: 100%;
    }
    .aboutsec2card1 h1{
        font-size: 3rem;
        margin: 1rem 0rem;
    }
    .aboutsec2card1 p{
        font-size: 1.5rem;
        margin: 1rem 0rem;
        text-align: justify;
    }
    .aboutsec2boxs{
        display: flex;
        width: 25rem;
        /* border: 1px solid black; */
        margin: 0.4rem;
    }
    .aboutsec2boxsimg{
        width: 4rem;
        height: 4rem;
        /* background-color: rgb(160, 160, 220); */
        border-radius: 200px;
        margin: 2rem 0.5rem;
        /* overflow: hidden; */
    }
    .aboutsec2boxsimg img{
        width: 100%; 
        height: 100%;
       
    }
    .aboutsec2boxscontent{
        padding: 0.3rem;
        width: 17rem;
    }
    .aboutsec2boxscontent p{
        font-size: 1rem;
        line-height: 2rem;
        text-align: justify;
    }
    .aboutsec2boxscontent h2{
        font-size: 1.9rem;
        margin: 0;
    }
    .aboutsec2box2{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 3rem 0rem;
    }

      
    .aboutsec2boxsimg i{
        font-size: 3rem;
        text-align: center;
        color: #ff6500;
    }
        
}
@media (min-width:1200px) and (max-width:1400px){

    .aboutsec2container{
        /* width: 90%; */
        margin: auto;
    }
    .aboutsec2box1{
        display: flex;
        justify-content: space-between;
        margin: 4rem 0rem;
        padding: 0rem 3rem;
    }
    .aboutsec2card2{
        width: 122rem;
        margin: 5rem 1rem;
    }
    .aboutsec2card2 img{
        width: 100%;
    }
    .aboutsec2card1 h1{
        font-size: 3rem;
        margin: 1rem 0rem;
    }
    .aboutsec2card1 p{
        font-size: 1.5rem;
        margin: 1rem 0rem;
        text-align: justify;
    }
    .aboutsec2boxs{
        display: flex;
        width: 25rem;
        /* border: 1px solid black; */
        margin: 0.4rem;
    }
    .aboutsec2boxsimg{
        width: 4rem;
        height: 4rem;
        /* background-color: rgb(160, 160, 220); */
        border-radius: 200px;
        margin: 2rem 0.5rem;
        /* overflow: hidden; */
    }
    .aboutsec2boxsimg img{
        width: 100%; 
        height: 100%;
       
    }
    .aboutsec2boxscontent{
        padding: 0.3rem;
        width: 17rem;
    }
    .aboutsec2boxscontent p{
        font-size: 1rem;
        line-height: 2rem;
        text-align: justify;
    }
    .aboutsec2boxscontent h2{
        font-size: 1.9rem;
        margin: 0;
    }
    .aboutsec2box2{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 3rem 0rem;
    }

      
    .aboutsec2boxsimg i{
        font-size: 3rem;
        text-align: center;
        color: #ff6500;
    }
        
}

@media (min-width:1400px) {
    .aboutsec2container{
        /* width: 90%; */
        margin: auto;
    }
    .aboutsec2box1{
        display: flex;
        justify-content: space-between;
        margin: 4rem 0rem;
        padding: 0rem 3rem;
    }
    .aboutsec2card2{
        width: 122rem;
        margin: 3rem;
        /* height: 50rem; */
        position: relative;
    }
    .aboutsec2card2 img{
        width: 100%;
        /* height: 100%; */
    }
    
    .aboutsec2card1 h1{
        font-size: 3rem;
        margin: 1rem 0rem;
    }
    .aboutsec2card1 p{
        font-size: 1.5rem;
        margin: 1rem 0rem;
        text-align: justify;
    }
    
    .aboutsec2boxs{
        display: flex;
        width: 25rem;
        /* border: 1px solid black; */
        margin: 0.4rem;
    }
    .aboutsec2boxsimg{
        width: 4rem;
        height: 4rem;
        /* background-color: rgb(160, 160, 220); */
        border-radius: 200px;
        margin: 2rem 0.5rem;
        /* overflow: hidden; */
    }
    .aboutsec2boxsimg img{
        width: 100%; 
        /* height: 100%; */
       
    }
    .aboutsec2boxscontent{
        padding: 0.3rem;
        width: 17rem;
    }
    .aboutsec2boxscontent p{
        font-size: 1rem;
        line-height: 2rem;
        text-align: justify;
    }
    .aboutsec2boxscontent h2{
        font-size: 1.9rem;
        margin: 0;
    }
    
    .aboutsec2box2{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 3rem 0rem;
    }
    
    
    .aboutsec2boxsimg i{
        font-size: 3rem;
        text-align: center;
        color: #ff6500;
    }

    .imgavouthover{
        position: absolute;
        width: 100%;
        top: 0;
    }
    .imgavouthover img{
        /* position: absolute; */
        width: 100%;
        
    }
    .imgavouthover:hover {
        transform: scale(1.3);
        transition: all 2s;
    }
    
       
        
}