

@media (min-width:05px) and (max-width:765px){
    .aboutsec1container{
        /* background-image: url('../../img/aboutimg2.webp');    */
        background-image: url('../../img/1.jpg');
        background-position: center;
        background-size: 100%;
        position: relative;
        z-index: 1;
        background-size: cover;
    }
    .aboutcard {
        width: 90%;
        margin: auto;
       
    }
    .aboutcard h3{
        font-size: 3rem;
        padding-top: 11rem;
        /* color: white; */
        color: #ff6500;
       
    }
    
    .aboutcard p{
        font-size: 1.3rem;
        padding-bottom: 4rem;
        width: 96%;
        color: black;
        line-gap-override: 1px;
        text-align: justify;
      
       
    }
    .aboutsec1container::after{
      
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}
@media (min-width:765px){
    .aboutsec1container{
        /* background-image: url('../../img/aboutimg2.webp');    */
        background-image: url('../../img/1.jpg');
        background-position: center;
        background-size: 100%;
        position: relative;
        z-index: 1;
    }
    .aboutcard {
        width: 90%;
        margin: auto;
       
    }
    .aboutcard h3{
        font-size: 3rem;
        padding-top: 11rem;
        /* color: white; */
        color: #ff6500;
       
    }
    
    .aboutcard p{
        font-size: 1.3rem;
        padding-bottom: 4rem;
        width: 96%;
        color: black;
        line-gap-override: 1px;
        text-align: justify;
      
       
    }
    .aboutsec1container::after{
      
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}