
@media (min-width:05px) and (max-width:600px){

    .piccontentboxs{
        display: flex;
        flex-wrap: wrap;
    }
    .piccontentbox{
        width: 80%;
        margin: 2rem auto;
        position: relative;

    }
    .piccontentbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* text-align: center; */
        flex-wrap: wrap;
    }
    .piccontentbox h6{
        font-size: 1rem;
        margin: 4px;
        color: rgb(80, 43, 43);
        font-weight: 100;
        text-align: justify;
    }
    .piccontentbox img{
        width: 100%;
    }
    
    .piccontentbutton button{
        padding: 0.5rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        background-color: rgb(163, 163, 249);
        border: none;
    }

    .piccontentbox .hoverimgeffect{
        position: absolute;
    }

    .hoverimgeffect:hover{
        transition: all 1s;
        transform: scale(1.2);
    }


    
.piccontentbutton1{
    padding: 0.5rem;
        position: relative;
        display: inline;
        width: 9.5rem;
        
}
.piccontentbutton1 button{
    background-color: #ff6500;
        padding:0.5rem 0.9rem;
        font-size: 1.3rem;
        color: white;
        position: relative;
        border: none;
        z-index: 99;
}


.piccontentbutton1::before{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-top: 4px solid #ff6500;
    border-left: 4px solid #ff6500;
    top: 0;
    left: 0;
    transition: all 1s;

}
.piccontentbutton1::after{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-bottom: 4px solid #ff6500;
    border-right: 4px solid #ff6500;
    bottom: 0;
    right: 0;
    transition: all 1s;

}

.piccontentbutton1:hover:before,.piccontentbutton1:hover:after{
    width: 90%;
    height: 80%;
    /* transform: rotate(360deg); */
}

.piccontentbutton1 button a{
    
        color: white;
        text-decoration: none;
        
}

    
}
@media (min-width:600px) and (max-width:900px){

    .piccontentboxs{
        display: flex;
    }
    .piccontentbox{
        width: 40%;
        margin: 2rem;
        position: relative;
    }
    .piccontentbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* text-align: center; */
        flex-wrap: wrap;
    }
    .piccontentbox h6{
        font-size: 1.1rem;
        margin: 4px;
        color: rgb(80, 43, 43);
        font-weight: 100;
        text-align: justify;
    }
    .piccontentbox img{
        width: 100%;
    }
    
    .piccontentbutton button{
        padding: 0.5rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        background-color: rgb(163, 163, 249);
        border: none;
    }
    .piccontentbox .hoverimgeffect{
        position: absolute;
    }

    .hoverimgeffect:hover{
        transition: all 1s;
        transform: scale(1.2);
    }


    
.piccontentbutton1{
    padding: 0.5rem;
        position: relative;
        display: inline;
        width: 9.5rem;
        
}
.piccontentbutton1 button{
    background-color: #ff6500;
        padding:0.5rem 0.9rem;
        font-size: 1.3rem;
        color: white;
        position: relative;
        border: none;
        z-index: 99;
}


.piccontentbutton1::before{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-top: 4px solid #ff6500;
    border-left: 4px solid #ff6500;
    top: 0;
    left: 0;
    transition: all 1s;

}
.piccontentbutton1::after{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-bottom: 4px solid #ff6500;
    border-right: 4px solid #ff6500;
    bottom: 0;
    right: 0;
    transition: all 1s;

}

.piccontentbutton1:hover:before,.piccontentbutton1:hover:after{
    width: 90%;
    height: 80%;
    /* transform: rotate(360deg); */
}

.piccontentbutton1 button a{
    
        color: white;
        text-decoration: none;
        
}

    
}
@media (min-width:900px) and (max-width:1241px){

    .piccontentboxs{
        display: flex;
    }
    .piccontentbox{
        width: 40%;
        margin: 2rem;
        position: relative;
    }
    .piccontentbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* text-align: center; */
        flex-wrap: wrap;
    }
    .piccontentbox h6{
        font-size: 1.3rem;
        margin: 4px;
        color: rgb(80, 43, 43);
        font-weight: 100; 
        text-align: justify;
       }

    .piccontentbox h4{
        font-size: 1.7rem;
        margin: 4px;
        color: rgb(80, 43, 43);
       
    }
    /* .piccontentbox img{
        width: 100%;
    } */
    
    .piccontentbutton button{
        padding: 0.5rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        background-color: rgb(163, 163, 249);
        border: none;
    }


    
    .piccontentbox .hoverimgeffect{
        position: absolute;
    }

    .hoverimgeffect:hover{
        transition: all 1s;
        transform: scale(1.2);
    }

    .piccontentbox h4 span{
        color: #ff6500;
    }


    
.piccontentbutton1{
    padding: 0.5rem;
        position: relative;
        display: inline;
        width: 9.5rem;
        
}
.piccontentbutton1 button{
    background-color: #ff6500;
        padding:0.5rem 0.9rem;
        font-size: 1.3rem;
        color: white;
        position: relative;
        border: none;
        z-index: 99;
}


.piccontentbutton1::before{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-top: 4px solid #ff6500;
    border-left: 4px solid #ff6500;
    top: 0;
    left: 0;
    transition: all 1s;

}
.piccontentbutton1::after{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-bottom: 4px solid #ff6500;
    border-right: 4px solid #ff6500;
    bottom: 0;
    right: 0;
    transition: all 1s;

}

.piccontentbutton1:hover:before,.piccontentbutton1:hover:after{
    width: 90%;
    height: 80%;
    /* transform: rotate(360deg); */
}

.piccontentbutton1 button a{
    
        color: white;
        text-decoration: none;
        
}

    
    
}

@media (min-width:1241px) and (max-width:1600px) {
    .piccontentboxs{
        display: flex;
    }
    .piccontentbox{
        width: 40%;
        margin: 2rem;
    }
    .piccontentbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* text-align: center; */
        position: relative;
    }
    .piccontentbox h6{
        font-size: 1.3rem;
        margin: 4px;
        color: rgb(80, 43, 43);
        font-weight: 100;
        text-align: justify;
    }
    .piccontentbox h4{
        font-size: 1.8rem;
        margin: 4px;
        color: rgb(80, 43, 43);
       
    }
    /* .piccontentbox img{
        width: 100%;
    } */
    
    .piccontentbutton button{
        padding: 0.5rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        background-color: rgb(163, 163, 249);
        border: none;
    }
    
    .hoverimgeffect{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    

    .hoverimgeffect:hover{
        transform: scale(1.3);
        transition:all 2s;
    }

    .piccontentbox h4 span{
        color: #ff6500;
    }

    
.piccontentbutton1{
    padding: 0.5rem;
        position: relative;
        display: inline;
        width: 9.5rem;
        
}
.piccontentbutton1 button{
    background-color: #ff6500;
        padding:0.5rem 0.9rem;
        font-size: 1.3rem;
        color: white;
        position: relative;
        border: none;
        z-index: 99;
}


.piccontentbutton1::before{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-top: 4px solid #ff6500;
    border-left: 4px solid #ff6500;
    top: 0;
    left: 0;
    transition: all 1s;

}
.piccontentbutton1::after{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-bottom: 4px solid #ff6500;
    border-right: 4px solid #ff6500;
    bottom: 0;
    right: 0;
    transition: all 1s;

}

.piccontentbutton1:hover:before,.piccontentbutton1:hover:after{
    width: 90%;
    height: 80%;
    /* transform: rotate(360deg); */
}

.piccontentbutton1 button a{
    
        color: white;
        text-decoration: none;
        
}

    
}
@media (min-width:1600px) and (max-width:1800px) {
    .piccontentboxs{
        display: flex;
    }
    .piccontentbox{
        width: 40%;
        margin: 2rem;
    }
    .piccontentbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* text-align: center; */
        position: relative;
    }
    .piccontentbox h6{
        font-size: 1.6rem;
        margin: 4px;
        color: rgb(80, 43, 43);
        font-weight: 100;
        text-align: justify;
    }
    .piccontentbox h4{
        font-size: 2rem;
        margin: 4px;
        color: rgb(80, 43, 43);
       
    }
    /* .piccontentbox img{
        width: 100%;
    } */
    
    .piccontentbutton button{
        padding: 0.5rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        background-color: rgb(163, 163, 249);
        border: none;
    }
    
    .hoverimgeffect{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    

    .hoverimgeffect:hover{
        transform: scale(1.3);
        transition:all 2s;
    }

    .piccontentbox h4 span{
        color: #ff6500;
    }

    
.piccontentbutton1{
    padding: 0.5rem;
        position: relative;
        display: inline;
        width: 9.5rem;
        
}
.piccontentbutton1 button{
    background-color: #ff6500;
        padding:0.5rem 0.9rem;
        font-size: 1.3rem;
        color: white;
        position: relative;
        border: none;
        z-index: 99;
}


.piccontentbutton1::before{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-top: 4px solid #ff6500;
    border-left: 4px solid #ff6500;
    top: 0;
    left: 0;
    transition: all 1s;

}
.piccontentbutton1::after{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-bottom: 4px solid #ff6500;
    border-right: 4px solid #ff6500;
    bottom: 0;
    right: 0;
    transition: all 1s;

}

.piccontentbutton1:hover:before,.piccontentbutton1:hover:after{
    width: 90%;
    height: 80%;
    /* transform: rotate(360deg); */
}

.piccontentbutton1 button a{
    
        color: white;
        text-decoration: none;
        
}

    
}
@media (min-width:1800px) and (max-width:2000px) {
    .piccontentboxs{
        display: flex;
    }
    .piccontentbox{
        width: 40%;
        margin: 2rem;
    }
    .piccontentbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* text-align: center; */
        position: relative;
    }
    .piccontentbox h6{
        font-size: 1.8rem;
        margin: 4px;
        color: rgb(80, 43, 43);
        font-weight: 100;
        text-align: justify;
    }
    .piccontentbox h4{
        font-size: 2.2rem;
        margin: 4px;
        color: rgb(80, 43, 43);
       
    }
    /* .piccontentbox img{
        width: 100%;
    } */
    
    .piccontentbutton button{
        padding: 0.5rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        background-color: rgb(163, 163, 249);
        border: none;
    }
    
    .hoverimgeffect{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    

    .hoverimgeffect:hover{
        transform: scale(1.3);
        transition:all 2s;
    }

    .piccontentbox h4 span{
        color: #ff6500;
    }

    
.piccontentbutton1{
    padding: 0.5rem;
        position: relative;
        display: inline;
        width: 9.5rem;
        
}
.piccontentbutton1 button{
    background-color: #ff6500;
        padding:0.5rem 0.9rem;
        font-size: 1.3rem;
        color: white;
        position: relative;
        border: none;
        z-index: 99;
}


.piccontentbutton1::before{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-top: 4px solid #ff6500;
    border-left: 4px solid #ff6500;
    top: 0;
    left: 0;
    transition: all 1s;

}
.piccontentbutton1::after{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-bottom: 4px solid #ff6500;
    border-right: 4px solid #ff6500;
    bottom: 0;
    right: 0;
    transition: all 1s;

}

.piccontentbutton1:hover:before,.piccontentbutton1:hover:after{
    width: 90%;
    height: 80%;
    /* transform: rotate(360deg); */
}

.piccontentbutton1 button a{
    
        color: white;
        text-decoration: none;
        
}

    
}
@media (min-width:2000px) {
    .piccontentboxs{
        display: flex;
    }
    .piccontentbox{
        width: 40%;
        margin: 2rem;
    }
    .piccontentbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* text-align: center; */
        position: relative;
    }
    .piccontentbox h6{
        font-size: 2.3rem;
        margin: 4px;
        color: rgb(80, 43, 43);
        font-weight: 100;
        text-align: justify;
    }
    .piccontentbox h4{
        font-size: 2.7rem;
        margin: 4px;
        color: rgb(80, 43, 43);
       
    }
    /* .piccontentbox img{
        width: 100%;
    } */
    
    .piccontentbutton button{
        padding: 0.5rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        background-color: rgb(163, 163, 249);
        border: none;
    }
    
    .hoverimgeffect{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    

    .hoverimgeffect:hover{
        transform: scale(1.3);
        transition:all 2s;
    }

    .piccontentbox h4 span{
        color: #ff6500;
    }

    
.piccontentbutton1{
    padding: 0.5rem;
        position: relative;
        display: inline;
        width: 13.5rem;
        
}
.piccontentbutton1 button{
    background-color: #ff6500;
        padding:0.5rem 0.9rem;
        font-size: 1.3rem;
        color: white;
        position: relative;
        border: none;
        z-index: 99;
}


.piccontentbutton1::before{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-top: 4px solid #ff6500;
    border-left: 4px solid #ff6500;
    top: 0;
    left: 0;
    transition: all 1s;

}
.piccontentbutton1::after{
    content: "";
    position: absolute;
    /* background-color: blue; */
    /* border: 4px solid yellow; */
    width: 10%;
    height: 15%;
    border-bottom: 4px solid #ff6500;
    border-right: 4px solid #ff6500;
    bottom: 0;
    right: 0;
    transition: all 1s;

}

.piccontentbutton1:hover:before,.piccontentbutton1:hover:after{
    width: 90%;
    height: 80%;
    /* transform: rotate(360deg); */
}

.piccontentbutton1 button a{
    
        color: white;
        text-decoration: none;
        font-size: 2rem;
        
}

    
}

