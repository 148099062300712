
@media (min-width:05px) and (max-width:657px){
   

    .contactsec1box a{
        text-decoration: none;
    }
    .contactsec1box p span{
        font-weight: bold;
        
    }
    .contactsec1boxs{
        display: flex;
        justify-content: space-around;
        text-align: center;
        flex-wrap: wrap;
    }
    .contactsec1box i{
        font-size: 3rem;
        color: #da3c2a;
        padding: 1rem;
    }
    .contactsec1box h3{
        font-size: 1.3rem;
        padding-bottom: 0.5rem;
        
    }
    .contactsec1box {
        
        padding: 1rem;
        
    }
    
    /* section2 */
    
    .contactsec2boxs{
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    }
    .contactsec2box1{
        width: 90%;
        text-align: center;
        position: relative;
        margin: 2rem;
        margin: auto;
    }
    .contactsec2box2{
        /* width: 45%; */
        /* text-align: center; */
        padding: 2rem;
    }
    .contactsec2box2 form{
        position: relative;
        
    }
    .forminputs input {
        padding: 0.4rem;
        margin: 0.3rem;
       border-top: none;
       border-left: none;
       border-right: none; 
       width: 44%;
       font-size: 1.2rem;
       border-bottom: 2px solid;
    
        
    }
    .forminputs textarea {
        padding: 0.4rem;
        margin: 0.3rem;
       border-top: none;
       border-left: none;
       border-right: none; 
       border-bottom: 2px solid;
       width: 88%;
       font-size: 1.2rem;
    
        
    }
    .contactbutton button{
        padding: 0.6rem 1rem;
        margin: 0.3rem;
        font-size: 1.1rem;
    }
           
    
    
           
}

@media (min-width:657px) and (max-width:925px){


    .contactsec1box a{
        text-decoration: none;
    }
    .contactsec1box p span{
        font-weight: bold;
        
    }
    .contactsec1boxs{
        display: flex;
        justify-content: space-around;
        text-align: center;
        flex-wrap: wrap;
    }
    .contactsec1box i{
        font-size: 3rem;
        color: #da3c2a;
        padding: 1rem;
    }
    .contactsec1box h3{
        font-size: 1.3rem;
        padding-bottom: 0.5rem;
        
    }
    .contactsec1box {
        
        padding: 1rem;
        
    }
    
    /* section2 */
    
    .contactsec2boxs{
    display: flex;
    padding: 1rem;
    }
    .contactsec2box1{
        width: 45%;
        text-align: center;
        position: relative;
        margin: 2rem;
    }
    .contactsec2box2{
        /* width: 45%; */
        /* text-align: center; */
        padding: 2rem;
    }
    .contactsec2box2 form{
        position: relative;
        
    }
    .forminputs input {
        padding: 0.4rem;
        margin: 0.3rem;
       border-top: none;
       border-left: none;
       border-right: none; 
       width: 47%;
       font-size: 1.2rem;
       border-bottom: 2px solid;
    
        
    }
    .forminputs textarea {
        padding: 0.4rem;
        margin: 0.3rem;
       border-top: none;
       border-left: none;
       border-right: none; 
       border-bottom: 2px solid;
       width: 96%;
       font-size: 1.2rem;
    
        
    }
    .contactbutton button{
        padding: 0.6rem 1rem;
        margin: 0.3rem;
        font-size: 1.1rem;
    }
           
       
        
}
@media (min-width:925px) and (max-width:1241px){


    .contactsec1box a{
        text-decoration: none;
    }
    .contactsec1box p span{
        font-weight: bold;
        
    }
    .contactsec1boxs{
        display: flex;
        justify-content: space-around;
        text-align: center;
        flex-wrap: wrap;
    }
    .contactsec1box i{
        font-size: 4rem;
        color: #da3c2a;
        padding: 1rem;
    }
    .contactsec1box h3{
        font-size: 1.8rem;
        padding-bottom: 0.5rem;
        
    }
    .contactsec1box {
        
        padding: 3rem;
        
    }
    
    /* section2 */
    
    .contactsec2boxs{
    display: flex;
    padding: 4rem;
    }
    .contactsec2box1{
        width: 45%;
        text-align: center;
        position: relative;
        margin: 2rem;
    }
    .contactsec2box2{
        /* width: 45%; */
        /* text-align: center; */
        padding: 2rem;
    }
    .contactsec2box2 form{
        position: relative;
        
    }
    .forminputs input {
        padding: 0.4rem;
        margin: 0.3rem;
       border-top: none;
       border-left: none;
       border-right: none; 
       width: 47%;
       font-size: 1.2rem;
       border-bottom: 2px solid;
    
        
    }
    .forminputs textarea {
        padding: 0.4rem;
        margin: 0.3rem;
       border-top: none;
       border-left: none;
       border-right: none; 
       border-bottom: 2px solid;
       width: 96%;
       font-size: 1.2rem;
    
        
    }
    .contactbutton button{
        padding: 0.6rem 1rem;
        margin: 0.3rem;
        font-size: 1.1rem;
    }
    
             
        
}

@media (min-width:1241px) {
    
    .contactsec1box a{
        text-decoration: none;
    }
    .contactsec1box p span{
        font-weight: bold;
        
    }
    
    .contactsec1boxs{
        display: flex;
        justify-content: space-around;
        text-align: center;
        flex-wrap: wrap;
    }
    .contactsec1box i{
        font-size: 4rem;
        color: #da3c2a;
        padding: 1rem;
    }
    .contactsec1box h3{
        font-size: 1.8rem;
        padding-bottom: 0.5rem;
        
    }
    .contactsec1box {
        
        padding: 3rem;
        
    }
    
    /* section2 */
    
    .contactsec2boxs{
    display: flex;
    padding: 4rem;
    }
    .contactsec2box1{
        width: 45%;
        text-align: center;
        position: relative;
        margin: 2rem;
    }
    .contactsec2box2{
        /* width: 45%; */
        /* text-align: center; */
        padding: 2rem;
    }
    .contactsec2box2 form{
        position: relative;
        
    }
    .forminputs input {
        padding: 0.4rem;
        margin: 0.3rem;
       border-top: none;
       border-left: none;
       border-right: none; 
       width: 47%;
       font-size: 1.2rem;
       border-bottom: 2px solid;
    
        
    }
    .forminputs textarea {
        padding: 0.4rem;
        margin: 0.3rem;
       border-top: none;
       border-left: none;
       border-right: none; 
       border-bottom: 2px solid;
       width: 96%;
       font-size: 1.2rem;
    
        
    }
    .contactbutton button{
        padding: 0.6rem 1rem;
        margin: 0.3rem;
        font-size: 1.1rem;
    }
    
    
        
}