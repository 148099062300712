
@media (min-width:05px) and (max-width:500px){

    .footercontainer{
        background-image:url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTWrU4xSa08tnDwYg0bBdk8Hje7fIaUTXD4g&usqp=CAU');
        background-position:center;
        background-size: 100%;
        z-index: 1;
        position: relative;
        padding: 2rem;
    }
    .footercontainer::after{
        background: hsl(0deg 0% 0% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
    .footerboxs{
        display: flex;
        justify-content: space-between;
        /* width: 90%; */
        margin: auto;
        color: white;
        flex-wrap: wrap;
    }
    .footerbox h3{
        font-size: 1.5rem;
        padding: 0.7rem;
        margin: 0.5rem;
    }
    .footersocillinks .ilogo{
        font-size: 2rem;
        padding: 0.5rem;
        margin: 0.4rem;
    }
    
    .footerlinks ul li{
        list-style: none;
        padding: 0.3rem;
    }
    .footerlinks ul li a{
     text-decoration: none;
     color: white;
     padding: 0.4rem;
     /* margin: 1rem; */
    }
    
    .footercontent h4{
        width: 15rem;
        text-align: justify;
    }
    .footerconditons {
        color: white;
        padding: 0.3rem;
        margin: 0.3rem;
    
    }
    .footerconditons span{
        color: white;
        padding: 0.3rem;
        margin: 0.3rem;
    
    }


    .showmodelfooter{
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1200;
        background-color:transparent;
        display: none;
        text-align: center;
        /* display: flex; */
        /* position: relative; */
       
    }
    .showmodelfooter::after{
        background: rgba(8, 6, 19, 0.9);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
    
    .open{
        display: block;
      }
    
    
    
    .termpolicybox{
        background-color: white;
        width: 60%;
        height: 80vh;
        margin: auto;
        padding-top: 3rem;
        margin-top: 4rem;
        overflow:scroll ;
    }
    
    .termpolicybox{
        text-align: left;
        padding: 2rem;
    }
    
    
    .closebutton i{
        font-size: 3rem;
        color: white;
        position: absolute;
        top: 1rem;
        right: 10rem;
    
    }
    
        
}

@media (min-width:500px) and (max-width:1241px){

    .footercontainer{
        background-image:url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTWrU4xSa08tnDwYg0bBdk8Hje7fIaUTXD4g&usqp=CAU');
        background-position:center;
        background-size: 100%;
        z-index: 1;
        position: relative;
        padding: 2rem;
    }
    .footercontainer::after{
        background: hsl(0deg 0% 0% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
    .footerboxs{
        display: flex;
        justify-content: space-between;
        /* width: 90%; */
        margin: auto;
        color: white;
        flex-wrap: wrap;
    }
    .footerbox h3{
        font-size: 1.5rem;
        padding: 0.7rem;
        margin: 0.5rem;
    }
    .footersocillinks .ilogo{
        font-size: 2rem;
        padding: 0.5rem;
        margin: 0.4rem;
    }
    
    .footerlinks ul li{
        list-style: none;
        padding: 0.3rem;
    }
    .footerlinks ul li a{
     text-decoration: none;
     color: white;
     padding: 0.4rem;
     /* margin: 1rem; */
    }
    
    .footercontent h4{
        width: 25rem;
        text-align: justify;
    }
    .footerconditons {
        color: white;
        padding: 0.3rem;
        margin: 0.3rem;
    
    }
    .footerconditons span{
        color: white;
        padding: 0.3rem;
        margin: 0.3rem;
    
    }


    .showmodelfooter{
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1200;
        background-color:transparent;
        display: none;
        text-align: center;
        /* display: flex; */
        /* position: relative; */
       
    }
    .showmodelfooter::after{
        background: rgba(8, 6, 19, 0.9);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
    
    .open{
        display: block;
      }
    
    
    
    .termpolicybox{
        background-color: white;
        width: 60%;
        height: 80vh;
        margin: auto;
        padding-top: 3rem;
        margin-top: 4rem;
        overflow:scroll ;
    }
    
    .termpolicybox{
        text-align: left;
        padding: 2rem;
    }
    
    
    .closebutton i{
        font-size: 3rem;
        color: white;
        position: absolute;
        top: 1rem;
        right: 12rem;
    
    }
    
        
}

@media (min-width:1241px) {
    .footercontainer{
        background-image:url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTWrU4xSa08tnDwYg0bBdk8Hje7fIaUTXD4g&usqp=CAU');
        background-position:center;
        background-size: 100%;
        z-index: 1;
        position: relative;
        padding: 2rem;
    }
    .footercontainer::after{
        background: hsl(0deg 0% 0% / 90%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
    .footerboxs{
        display: flex;
        justify-content: space-between;
        /* width: 90%; */
        margin: auto;
        color: white;
        flex-wrap: wrap;
    }
    .footerbox h3{
        font-size: 1.5rem;
        padding: 0.7rem;
        margin: 0.5rem;
    }
    .footersocillinks .ilogo{
        font-size: 2rem;
        padding: 0.5rem;
        margin: 0.4rem;
    }
    
    .footerlinks ul li{
        list-style: none;
        padding: 0.3rem;
    }
    .footerlinks ul li a{
     text-decoration: none;
     color: white;
     padding: 0.4rem;
     /* margin: 1rem; */
    }
    
    .footercontent h4{
        width: 25rem;
        text-align: justify;
    }
    .footerconditons {
        color: white;
        padding: 0.3rem;
        margin: 0.3rem;
    
    }
    .footerconditons span{
        color: white;
        padding: 0.3rem;
        margin: 0.3rem;
    
    }

.showmodelfooter{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    background-color:transparent;
    display: none;
    text-align: center;
    /* display: flex; */
    /* position: relative; */
   
}
.showmodelfooter::after{
    background: rgba(8, 6, 19, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.open{
    display: block;
  }



.termpolicybox{
    background-color: white;
    width: 60%;
    height: 80vh;
    margin: auto;
    padding-top: 3rem;
    margin-top: 4rem;
    overflow:scroll ;
}

.termpolicybox{
    text-align: left;
    padding: 2rem;
}


.closebutton i{
    font-size: 3rem;
    color: white;
    position: absolute;
    top: 3rem;
    right: 12rem;

}


    
        
}
