

@media (min-width:05px) and (max-width:500px) {

  
    .homemaincontainer{
        background-image: url('../img/sample.jpg');
        text-align: center;
        /* height: 44rem; */
    
        height: 29vh;
        background-position: center;
        width: 100%;
        background-size: cover;
    }
    
    .homemainheading {
        width: 50%;
        margin: auto;
    }
    .homemainheading h3{
        margin: 0px;
        padding-top:19rem ;
        font-size: 2.8rem;
        color: orange;
        text-align: left;
        /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
        /* font-family; */
    }
    
    .homemainheading h3 span{
        padding-left:13rem ;
    }
    
    .homemainbutton button{
        padding: 0.6rem;
        margin: 2rem;
        font-size: 1.4rem;
        background: transparent;
        border: 1px solid white;
        border-radius: 3rem;
        color: rgb(88 86 92);
        font-weight: bold;
    }
    

    
}

@media (min-width:500px) and (max-width:800px) {

  
    .homemaincontainer{
        background-image: url('../img/sample.jpg');
        text-align: center;
        /* height: 44rem; */
    
        height: 46vh;
        background-position: center;
        width: 100%;
        background-size: cover;
    }
    
    .homemainheading {
        width: 50%;
        margin: auto;
    }
    .homemainheading h3{
        margin: 0px;
        padding-top:19rem ;
        font-size: 2.8rem;
        color: orange;
        text-align: left;
        /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
        /* font-family; */
    }
    
    .homemainheading h3 span{
        padding-left:13rem ;
    }
    
    .homemainbutton button{
        padding: 0.6rem;
        margin: 2rem;
        font-size: 1.4rem;
        background: transparent;
        border: 1px solid white;
        border-radius: 3rem;
        color: rgb(88 86 92);
        font-weight: bold;
    }
    

    
}

@media (min-width:800px) and (max-width:1145px) {

  
    .homemaincontainer{
        background-image: url('../img/sample.jpg');
        text-align: center;
        /* height: 44rem; */
    
        height: 70vh;
        background-position: center;
        width: 100%;
        background-size: cover;
    }
    
    .homemainheading {
        width: 50%;
        margin: auto;
    }
    .homemainheading h3{
        margin: 0px;
        padding-top:19rem ;
        font-size: 2.8rem;
        color: orange;
        text-align: left;
        /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
        /* font-family; */
    }
    
    .homemainheading h3 span{
        padding-left:13rem ;
    }
    
    .homemainbutton button{
        padding: 0.6rem;
        margin: 2rem;
        font-size: 1.4rem;
        background: transparent;
        border: 1px solid white;
        border-radius: 3rem;
        color: rgb(88 86 92);
        font-weight: bold;
    }
    

    
}


@media (min-width:1145px) {

    .homemaincontainer{
        background-image: url('../img/sample.jpg');
        text-align: center;
        /* height: 44rem; */
    
        height: 100vh;
        background-position: center;
        width: 100%;
        background-size: cover;
    }
    
    .homemainheading {
        width: 50%;
        margin: auto;
    }
    .homemainheading h3{
        margin: 0px;
        padding-top:19rem ;
        font-size: 2.8rem;
        color: orange;
        text-align: left;
        /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
        /* font-family; */
    }
    
    .homemainheading h3 span{
        padding-left:13rem ;
    }
    
    .homemainbutton button{
        padding: 0.6rem;
        margin: 2rem;
        font-size: 1.4rem;
        background: transparent;
        border: 1px solid white;
        border-radius: 3rem;
        color: rgb(88 86 92);
        font-weight: bold;
    }
    


    
    
}

