.container{
    padding: 5rem 0rem;
}
.team1boxs{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}
.team1box{
    width: 250px;
    margin: 0.4rem;
    height: 300px;
    /* border: 1px solid black; */
    box-shadow: 1px 1px 12px blue;
    position: relative;
    

}
.team1profile{
    width: 100%;
    height: 200px;
}
.team1profile img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.team1content{
    text-align: center;
    padding: 1rem 0rem;
}

