*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::selection{
  /* background-color: white; */
  /* color: orange; */
}



@media (min-width:05px) and (max-width:600px){

  
.privacycontainer h2{
  
  font-size: 1rem;
  /* padding: 1rem 0rem; */


}
.privacycontainer p{
  
  font-size: 1rem;
  padding: 0.3rem 0rem;
  font-weight: 100;


} 

}


@media (min-width:600px){
  .privacycontainer h2{
  
    font-size: 1.5rem;
    padding: 1rem 0rem;
  
  
  }
  .privacycontainer p{
    
    font-size: 1.4rem;
    padding: 0.3rem 0rem;
    font-weight: 100;
  }

}
