


@media (min-width:05px) and (max-width:800px){
    .testimonialcontaner{
        text-align: center;
        padding: 1rem;
        /* background-color: rgb(240 228 228); */
    }
    
    .testimonialsupperbox{
        text-align: center;
        font-size: 1.2rem;
        padding: 5rem;
       
    }
    
    .testimonialsupperbox h4{
        /* padding: 0.5rem; */
        padding-top: 1.5rem;
    }
    .testimonialsupperbox h2{
        font-size: 2.6rem;
    }
    .testimonialsupperbox h2 span{
        color: blue;
    }
    .testimonialsupperbox p{
        text-align: center;
    }
    
    .testimonialcards{
        display: flex;
      
        position: relative;
        flex-wrap: wrap;
        
    
    
    }
    #card2{
        display: none;
    }
    .testimonialcard1{
        border: 1px solid black;
        padding: 1rem;
        width: 100%;
        
        text-align: justify;
        border: none;
        border-bottom: 1px solid;
    }
    .testimonialcard2{
        border: 1px solid black;
        padding: 3rem;
        width: 100%;
        border: none;
    }
    
    .testimonialcard2{
        /* text-align: center; */
    }
    .testimonialcard2 h5{
        font-size: 1.3rem;
        font-weight: 400;
        
    }
    .testimonialcard2 p{
        font-size: 1.3rem;
        padding-bottom: 0.5rem;
        font-weight: 200;
    }
    .testimonialcard2 h6 span i{
        font-size: 1.8rem;
        /* padding-bottom: 1rem; */
        color: rgb(139, 139, 22);
    }
    
    .testimonialcard1 h2{
        font-weight: 300;
    }
    .cardline{
        border-right: none;
        /* border-left: 1px solid black; */
    }
    .cardlast2{
        border-bottom: none;
    }
    
    
    
}

@media (min-width:800px) {
    .testimonialcontaner{
        text-align: center;
        padding: 2rem;
        /* background-color: rgb(240 228 228); */
    }
    
    .testimonialsupperbox{
        text-align: center;
        font-size: 1.2rem;
        padding: 5rem;
       
    }
    #card1{
        display: none;
    }
    
    .testimonialsupperbox h4{
        /* padding: 0.5rem; */
        padding-top: 1.5rem;
    }
    .testimonialsupperbox h2{
        font-size: 2.6rem;
    }
    .testimonialsupperbox h2 span{
        color: blue;
    }
    .testimonialsupperbox p{
        text-align: center;
    }
    
    .testimonialcards{
        display: flex;
        /* padding: 1rem; */
        position: relative;
        /* padding: 2rem; */
        /* border: 1px solid black; */
    
    
    }
    .testimonialcard1{
        border: 1px solid black;
        padding: 3rem;
        width: 65%;
        border-top:none;
        border-left: none;
        text-align: justify;
    }
    .testimonialcard2{
        border: 1px solid black;
        padding: 3rem;
        width: 35%;
        border-top: none; 
        border-right: none;
        border-left: none;
    }
    
    .testimonialcard2{
        /* text-align: center; */
    }
    .testimonialcard2 h5{
        font-size: 1.3rem;
        font-weight: 400;
        
    }
    .testimonialcard2 p{
        font-size: 1.3rem;
        padding-bottom: 0.5rem;
        font-weight: 200;
    }
    .testimonialcard2 h6 span i{
        font-size: 1.8rem;
        /* padding-bottom: 1rem; */
        color: rgb(139, 139, 22);
    }
    
    .testimonialcard1 h2{
        font-weight: 300;
    }
    .cardline{
        border-right: none;
        border-left: 1px solid black;
    }
    .cardlast2{
        border-bottom: none;
    }
    
    
    
}