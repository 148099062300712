

@media (min-width:05px) and (max-width:600px) {



.branchimg{
    position: relative;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;

}
.branchimg .img{
    padding: 2rem;
    width: 89%;
    border-radius: 100px;
    overflow: hidden;
}
.branchimg .img1{
    /* padding: 2rem; */
    width: 100%;
}
.branchimg .img img{
    width: 100%;
    border-radius: 180px;
   
}


.branchcontainer{
    /* background-color: #f4f1f6; */
}
.branchesbox{
    /* display: flex; */
    padding: 1rem;
    justify-content: center;
}
.branchbox{
    width: 90%;
    padding: 1rem;
    margin: auto;
}
.branchbox h1{
    padding: 2rem 0rem;
}
.branchbox h1 span{
    color: blue;
}
.branchbox p{
    font-size: 1.4rem;
    text-align: justify;
}

.sociallinks{
    width: 90%;
    margin: auto;
    padding: 1rem;
    text-align: center;
}
.sociallinks a{
    text-decoration: none;
}
.sociallinks .ilink{
    font-size: 2rem;
    padding: 0.4rem;
}

.branchpart2{
    /* padding: 1rem; */
    text-align: justify;
    position: relative;
}
.branchpart2::before{
    background-image: url('../img/23.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: rgb(64, 62, 62); */
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
.branchpart2 p{
  
    text-align: justify;
    font-size: 1.4rem;
    padding: 1rem 3rem ;
}

.branchproject{
    padding: 1rem;
}
.branchproject h2{
    font-size: 2rem;
    width: 95%;
    margin: auto;
    padding: 0rem 1.6rem;
}
.branchprojectimg{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.branchimgs{
    width: 12rem;
}
.branchimgs img{
    width: 100%;
    padding: 0.5rem;
}
.branchproject p{
    font-size: 1.4rem;
    text-align: justify;
    width: 84%;
    margin: auto;
    padding: 1.3rem 0rem;
}




}

@media (min-width:600px) and (max-width:800px){


    
.branchimg{
    position: relative;
   display: flex;

}
.branchimg .img{
    /* padding: 2rem; */
    width: 30%;
    border-radius: 100px;
    overflow: hidden;
}
.branchimg .img1{
    padding: 1rem;
    width: 70%;
}
.branchimg .img img{
    width: 100%;
    border-radius: 180px;
   
}


.branchcontainer{
    /* background-color: #f4f1f6; */
}
.branchesbox{
    /* display: flex; */
    padding: 1rem;
    justify-content: center;
}
.branchbox{
    width: 90%;
    padding: 1rem;
    margin: auto;
}
.branchbox h1{
    padding: 2rem 0rem;
}
.branchbox h1 span{
    color: blue;
}
.branchbox p{
    font-size: 1.4rem;
    text-align: justify;
}

.sociallinks{
    width: 90%;
    margin: auto;
    padding: 1rem;
    text-align: center;
}
.sociallinks a{
    text-decoration: none;
}
.sociallinks .ilink{
    font-size: 2rem;
    padding: 0.4rem;
}

.branchpart2{
    /* padding: 1rem; */
    text-align: justify;
    position: relative;
}
.branchpart2::before{
    background-image: url('../img/23.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: rgb(64, 62, 62); */
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
.branchpart2 p{
  
    text-align: justify;
    font-size: 1.4rem;
    padding: 1rem 3rem ;
}

.branchproject{
    padding: 1rem;
}
.branchproject h2{
    font-size: 2rem;
    width: 95%;
    margin: auto;
    padding: 0rem 1.6rem;
}
.branchprojectimg{
    display: flex;
    justify-content: space-around;
}

.branchimgs{
    width: 12rem;
}
.branchimgs img{
    width: 100%;
    padding: 0.5rem;
}
.branchproject p{
    font-size: 1.4rem;
    text-align: justify;
    width: 84%;
    margin: auto;
    padding: 1.3rem 0rem;
}



}
@media (min-width:800px){
   
.branchimg{
    position: relative;
   display: flex;

}
.branchimg .img{
    padding: 2rem;
    width: 30%;
    border-radius: 100px;
    overflow: hidden;
}
.branchimg .img1{
    padding: 2rem;
    width: 70%;
}
.branchimg .img img{
    width: 100%;
    border-radius: 180px;
   
}

.branchcontainer{
    /* background-color: #f4f1f6; */
}
.branchesbox{
    /* display: flex; */
    padding: 1rem;
    justify-content: center;
}
.branchbox{
    width: 90%;
    padding: 1rem;
    margin: auto;
}
.branchbox h1{
    padding: 2rem 0rem;
}
.branchbox h1 span{
    color: blue;
}
.branchbox p{
    font-size: 1.4rem;
    text-align: justify;
}

.sociallinks{
    width: 90%;
    margin: auto;
    padding: 1rem;
    text-align: center;
}
.sociallinks a{
    text-decoration: none;
}
.sociallinks .ilink{
    font-size: 2rem;
    padding: 0.4rem;
}

.branchpart2{
    /* padding: 1rem; */
    text-align: justify;
    position: relative;
}
.branchpart2::before{
    background-image: url('../img/23.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: rgb(64, 62, 62); */
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
.branchpart2 p{
  
    text-align: justify;
    font-size: 1.4rem;
    padding: 1rem 3rem ;
}


.branchproject{
    padding: 1rem;
}
.branchproject h2{
    font-size: 2rem;
    width: 95%;
    margin: auto;
    padding: 0rem 1.6rem;
}
.branchprojectimg{
    display: flex;
    justify-content: space-around;
}

.branchimgs{
    width: 12rem;
}
.branchimgs img{
    width: 100%;
    padding: 0.5rem;
}
.branchproject p{
    font-size: 1.4rem;
    text-align: justify;
    width: 84%;
    margin: auto;
    padding: 1.3rem 0rem;
}


}
 



