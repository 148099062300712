 
    /* SLIDER */
    
    .slider{
    
        height: 250px;
        margin: 3rem auto;
        position: relative;
        width: 90%;
        display: grid;
        place-items: center;
        overflow: hidden;
    
    
    }
    
    .slider-track{
        display: flex;
        width: calc(250px *18);
        animation: scroll 40s linear infinite;
    }
    .slider-track:hover{
    
        animation-play-state: paused;
    }
    
    @keyframes scroll { 
        0%{
            /* transform: translateX(10%); */
            transform: translateX(0);
        }
        100%{
            transform: translateX(calc(-250px * 9));
            /* transform: translateX(-100%); */
        }
        
    }
    
    
    .slide{ 
        height: 200px;
        width: 250px;
        display: flex;
        align-items: center;
        padding: 15px;
    }
    img{
        width: 100%;
    }
    .slide img:hover{
        /* transform: scale(1.3); */
        transform: scale(1.3);
        transition:all 2s;
    }
.sliderbox{
    position: relative;
}

.sliderbox::before{
    content: " ";

    position: absolute;
    top: 1.5rem;
    left: 0;
    width: 100%;
    height: 80%;
    /* background-color: blue; */
    background-image: url("../img/23.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

    
    
