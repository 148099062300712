

@media (min-width:05px) and (max-width:600px){

    .runingtextcontainer{
        width: 78%;
        margin:  7rem auto;
        text-align: center;
    }
    .runningtextmid h3{
        font-size: 1.6rem;
        margin: 0;
        display: inline;
    
    }
    .runningtextmid{
        color: black;
        font-size: 1.6rem;
        font-weight: bold;
    }
    .Typewriter{
        display: inline;
        color: #ff6500;
    }
    
}
@media (min-width:600px) and (max-width:900px){

    .runingtextcontainer{
        width: 78%;
        margin:  7rem auto;
        text-align: center;
    }
    .runningtextmid h3{
        font-size: 2.2rem;
        margin: 0;
        display: inline;
    
    }
    .runningtextmid{
        color: black;
        font-size: 2.2rem;
        font-weight: bold;
    }
    .Typewriter{
        display: inline;
        color: #ff6500;
    }
   
       
}
@media (min-width:900px) and (max-width:1241px){

    .runingtextcontainer{
        width: 78%;
        margin:  7rem auto;
        text-align: center;
    }
    .runningtextmid h3{
        font-size: 2.7rem;
        margin: 0;
        display: inline;
    
    }
    .runningtextmid{
        color: black;
        font-size: 2.7rem;
        font-weight: bold;
    }
    .Typewriter{
        display: inline;
        color: #ff6500;
    }
       
}

@media (min-width:1241px) and (max-width:1600px) {
    
    
    .runingtextcontainer{
        width: 78%;
        margin:  7rem auto;
        text-align: center;
    }
    .runningtextmid h3{
        font-size: 3rem;
        margin: 0;
        display: inline;
    
    }
    .runningtextmid{
        color: black;
        font-size: 3rem;
        font-weight: bold;
    }
    .Typewriter{
        display: inline;
        color: #ff6500;
    }
       
    
}
@media (min-width:1600px) and (max-width:2100px)  {
    
    .runningtextheading p{
        font-size: 1.4rem;
    }

    .runingtextcontainer{
        width: 78%;
        margin:  7rem auto;
        text-align: center;
    }
    .runningtextmid h3{
        font-size: 4rem;
        margin: 0;
        display: inline;
    
    }
    .runningtextmid{
        color: black;
        font-size: 3rem;
        font-weight: bold;
    }
    .Typewriter{
        display: inline;
        color: #ff6500;
        font-size: 4rem;
    }
       
    
}
@media (min-width:2100px) {
    
    .runningtextheading p{
        font-size: 1.6rem;
    }

    .runingtextcontainer{
        width: 78%;
        margin:  7rem auto;
        text-align: center;
    }
    .runningtextmid h3{
        font-size: 4.6rem;
        margin: 0;
        display: inline;
    
    }
    .runningtextmid{
        color: black;
        font-size: 3rem;
        font-weight: bold;
    }
    .Typewriter{
        display: inline;
        color: #ff6500;
        font-size: 4rem;
    }
       
    
}