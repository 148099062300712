


@media (min-width:05px) and (max-width:830px) {

    .showhidecontent1{
        display: none;
    }

    .historysec2{
        padding: 2rem;
    }
    .historysec2box{
        display: flex;
        flex-wrap: wrap;
    }
    .historysecbox1{
        width: 95%;
        margin:1rem;
    }
    .historysec2box2{
        width: 95%;
        position: relative;
        margin: 1rem;
    }
    .historysec2box2 img{
        width: 100%;
    }
    .historysecbox1 h6{
        font-size: 1.2rem;
        /* font-weight: 100; */
    }
    .historysecbox1 h3{
        font-size: 2.2rem;
        /* font-weight: 100; */
    }
    .historysecbox1 p{
        font-size: 1.2rem;
        font-weight: 100;
        text-align: justify;
    }
    

    .history2content {
        padding: 1rem;
    }
    .history2content h5{
        font-size: 1.3rem;
        padding: 0.6rem 0rem;
    }
    .history2content p{
        font-size: 1.3rem;
        font-weight: 100;
        text-align: justify;
    }



}
@media (min-width:830px) and (max-width:900px) {



    .historysec2{
        padding: 2rem;
    }
    .historysec2box{
        display: flex;
    }
    .historysecbox1{
        width: 47%;
        margin:1rem;
    }
    .historysec2box2{
        width: 47%;
        position: relative;
        margin: 1rem;
    }
    .historysec2box2 img{
        width: 100%;
    }
    .historysecbox1 h6{
        font-size: 1rem;
        /* font-weight: 100; */
    }
    .historysecbox1 h3{
        font-size: 2rem;
        /* font-weight: 100; */
    }
    .historysecbox1 p{
        font-size: 1.1rem;
        font-weight: 100;
        text-align: justify;
    }
    

    .history2content {
        padding: 1rem;
    }
    .history2content h5{
        font-size: 1.3rem;
        padding: 0.6rem 0rem;
    }
    .history2content p{
        font-size: 1.3rem;
        font-weight: 100;
        text-align: justify;
    }

    .showhidecontent1{
        display: none;
    }

}

@media (min-width:900px) and (max-width:1270px) {


    
.historysec2{
    padding: 2rem;
}
.historysec2box{
    display: flex;
}
.historysecbox1{
    width: 47%;
    margin:1rem;
}
.historysec2box2{
    width: 47%;
    position: relative;
    margin: 1rem;
  
}
.historysec2box2 img{
    width: 100%;
}
.historysecbox1 h6{
    font-size: 1.3rem;
    /* font-weight: 100; */
}
.historysecbox1 h3{
    font-size: 2.3rem;
    /* font-weight: 100; */
}
.historysecbox1 p{
    font-size: 1.3rem;
    font-weight: 100;
    text-align: justify;
}

.history2content {
    padding: 1rem;
}
.history2content h5{
    font-size: 1.3rem;
    padding: 0.6rem 0rem;
}
.history2content p{
    font-size: 1.3rem;
    font-weight: 100; 
    text-align: justify;
}

.showhidecontent1{
    display: none;
}


}
@media (min-width:1270px) and (max-width:1456px) {


    
.historysec2{
    padding: 2rem;
}
.historysec2box{
    display: flex;
}
.historysecbox1{
    width: 47%;
    margin:1rem;
}
.historysec2box2{
    width: 47%;
    position: relative;
    /* margin: 1rem; */
    padding-top: 5rem;
    padding-left: 1.4rem;
}
.historysec2box2 img{
    width: 100%;
}
.historysecbox1 h6{
    font-size: 1.3rem;
    /* font-weight: 100; */
}
.historysecbox1 h3{
    font-size: 2.3rem;
    /* font-weight: 100; */
}
.historysecbox1 p{
    font-size: 1.3rem;
    font-weight: 100;
    text-align: justify;
}

.history2content {
    padding: 1rem;
}
.history2content h5{
    font-size: 1.3rem;
    padding: 0.6rem 0rem;
}
.history2content p{
    font-size: 1.3rem;
    font-weight: 100; 
    text-align: justify;
}
.showhidecontent2{
    display: none;
}


}


@media (min-width:1456px) {

  
.historysec2{
    padding: 2rem;
}
.historysec2box{
    display: flex;
}
.historysecbox1{
    width: 47%;
    margin:1rem;
}
.historysec2box2{
    width: 47%;
    position: relative;
    margin: 1rem;
}
.historysec2box2 img{
    width: 100%;
}
.historysecbox1 h6{
    font-size: 1.3rem;
    /* font-weight: 100; */
}
.historysecbox1 h3{
    font-size: 2.3rem;
    /* font-weight: 100; */
}
.historysecbox1 p{
    font-size: 1.3rem;
    font-weight: 100;
    text-align: justify;
}
  
.history2content {
    padding: 1rem;
}
.history2content h5{
    font-size: 1.3rem;
    padding: 0.6rem 0rem;
}
.history2content p{
    font-size: 1.3rem;
    font-weight: 100;
    text-align: justify;
}

.showhidecontent2{
    display: none;
}



}





