

@media (min-width:0px) and (max-width:500px) {

  .projectboxs{
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.projectbox{
    width: 31%;
    margin: 0.6rem;

}
.projectbox img{
    width: 100%;
}



.gallarycontainer1{
    /* width: 95%;
    margin: auto; */
}

.gallarybox12{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.piccontainer{
    width: 100%;
    margin: 0.5rem;
    
}
.piccontainer img{
    width: 100%;

}





.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    background-color:transparent;
    display: none;
    text-align: center;
    /* display: flex; */
    /* position: relative; */
  }

  .model::after{
    background: rgba(8, 6, 19, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
 
.modelimgcontainer{
    width: 100%;
    /* height: 100vh; */
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding:7rem 1.5rem;
}
.modelimgcontainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;

}

  .open{
    display: block;
  }

  .modelimgcontainer h1 i{
    color: white;
  }


  

}
@media (min-width:500px) and (max-width:1000px) {

  
  .projectboxs{
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.projectbox{
    width: 31%;
    margin: 0.6rem;

}
.projectbox img{
    width: 100%;
}



.gallarycontainer1{
    /* width: 95%;
    margin: auto; */
}

.gallarybox12{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.piccontainer{
    width: 45%;
    margin: 0.5rem;
    
}
.piccontainer img{
    width: 100%;

}





.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    background-color:transparent;
    display: none;
    text-align: center;
    /* display: flex; */
    /* position: relative; */
  }

  .model::after{
    background: rgba(8, 6, 19, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
 
.modelimgcontainer{
    width: 80%;
    /* height: 100vh; */
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding:7rem 3rem;
}
.modelimgcontainer img{
  width: 100%;
  height: 100%;
  /* object-fit: cover; */

}

  .open{
    display: block;
  }

  .modelimgcontainer h1 i{
    color: white;
  }




}
@media (min-width:1000px) {


  .projectboxs{
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.projectbox{
    width: 31%;
    margin: 0.6rem;

}
.projectbox img{
    width: 100%;
}



.gallarycontainer1{
    /* width: 95%;
    margin: auto; */
}

.gallarybox12{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.piccontainer{
    width: 30%;
    margin: 0.5rem;
    
}
.piccontainer img{
    width: 100%;

}





.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    background-color:transparent;
    display: none;
    text-align: center;
    /* display: flex; */
    /* position: relative; */
  }

  .model::after{
    background: rgba(8, 6, 19, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
 
.modelimgcontainer{
    width: 50%;
    /* height: 100vh; */
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding:2rem 3rem;
}
.modelimgcontainer img{
  width: 100%;
  height: 100%;
  /* object-fit: cover; */

}

  .open{
    display: block;
  }

  .modelimgcontainer h1 i{
    color: white;
  }





}
