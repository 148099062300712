
/* toolbar */

.toolbar{
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    margin-left: 20px;
  margin-top: 10px;
  /* box-shadow: 1px 1px 1px 1px rgb(152, 152, 178); */
  }
  
  .imglogo img{
  width: 40px;
  margin-right: 17px;
  }
  .sidebar{
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    background-color: #d2c2c2;
    color: black;
    width: 280px;
    transform:translateX(-100%) ;
    transition: all 0.4s;
    z-index: 10000;
  }
  
  
  .downbar{
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 40%;
    background-color: black;
    color: white;
    width: 100%;
    transform:translateY(0%) ;
    transition: all 0.4s;
  }
  .profile{
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: 20px 80px;
    width: 100px;
  }
  
  .homelinks li{
  margin: 15px 30px;
    font-size: 22px;
    list-style: none;
    font-weight: bold;
    /* text-align: center; */
  }
  .homelinks li a{
  color: black;
  text-decoration: none;
  font-weight: bold;
  }
  .homelinks li i{
  margin-right: 30px;
  }
  .profileimg img{
    width: 100px;
    height: 100px;
    border-radius: 73px;
  }
  
  
  .sidebaropen{
    transform:translateX(0%) ;
    
  }
  .downbaropen{
    transform:translateY(0%) ;
    
  }
  .backdrop{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    display: none;
    z-index: 999;
  }
  
  .backdropopen{
    display: block;
  }


  .sideaboutlinks{
    background-color: #d2c2c2;
    /* box-shadow: 1px 1px 12px rgb(133, 126, 126); */
    z-index: 7000;
    display: none;
    /* position: absolute; */
    /* right: 0; */
    top: 1.5rem;
    /* left: 8rem; */
  }

  .aboutlinkssidebar{
    position: relative;
  }
 
  .aboutlinkssidebar:hover .sideaboutlinks {
    display: block;
  }

  .sideaboutlinks li:hover  a{
    color: rgb(93, 93, 120);
  }
  .sideaboutlinks li a{
    font-weight: 400;
  }




  